import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Markdown from 'react-markdown';
import LayoutContainer from '../containers/layout';
import PageContentComponent from '../components/page-content';
import PageTitleComponent from '../components/page-title';

const PrivacyPolicyPage = () => {
  const data = useStaticQuery(query);

  return (
    <LayoutContainer title={data.strapiPrivacyPolicy.title} description={data.strapiPrivacyPolicy.content}>
      <PageTitleComponent showBackground={false}>
        <div className="text-4xl lg:text-5xl text-black">{data.strapiPrivacyPolicy.title}</div>
      </PageTitleComponent>

      <PageContentComponent showBackground={false}>
        <Markdown className="no-widows w-full lg:w-3/5 lg:pr-20 markdown">{data.strapiPrivacyPolicy.content}</Markdown>
      </PageContentComponent>
    </LayoutContainer>
  );
};

const query = graphql`
  query {
    strapiPrivacyPolicy {
      title
      content
    }
  }
`;

export default PrivacyPolicyPage;
